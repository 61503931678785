import moment, { Moment } from 'moment';
import { OrgTreeNode } from '~/hooks/useOrgTree';
import { ReportingJobReportNameType } from '~/schemaTypes';
import { OrgState } from '~/views/ConfigDashboard/Organizations/Organizations';
import {
    AXIA_BRANDING_NAME,
    BHF_ORGANIZATION_NAME,
    HTC_HA_BRANDING_NAME,
    HTC_ORGANIZATION_NAME,
    reportingListOptions,
    PROGENY_HEALTH_BRANDING_NAME,
    WHASN_ORGANIZATION_NAME,
    ST_PETERS_BRANDING_NAME,
} from './OrganizationReporting';

export const getOrganizationIdFromOrgNode = (
    orgNode: Omit<OrgTreeNode, 'level'>,
    orgName: string,
): OrgTreeNode['org']['id'] => {
    let orgId = orgNode.org.brandingName === orgName ? orgNode.org.id : '';

    if (!orgId && orgNode.orgChildren.length) {
        const org = orgNode.orgChildren.length
            ? orgNode.orgChildren.find((org: OrgTreeNode) => org.org.brandingName === orgName)
            : undefined;

        if (org) {
            orgId = org.org.id;
        } else {
            orgNode.orgChildren.forEach((orgChild: OrgTreeNode) => {
                const orgChildId = getOrganizationIdFromOrgNode(orgChild, orgName);

                if (orgChildId) {
                    orgId = orgChildId;
                }
            });
        }
    }

    return orgId;
};

export const isCurrentPracticeChildOfCustomOrg = (
    ancestorsList: Array<OrgTreeNode['org']['brandingName']>,
    orgName: string,
): boolean => ancestorsList.some(ancestor => ancestor === orgName);

export const getReportingListOptionsItem = (
    orgState: OrgState,
    orgNode: Omit<OrgTreeNode, 'level'>,
    ancestorsBrandingNames: Array<OrgTreeNode['org']['brandingName']>,
) => {
    const htcOrChildOrganizationId = orgNode
        ? getOrganizationIdFromOrgNode(orgNode, HTC_ORGANIZATION_NAME)
        : null;

    const BhfOrChildOrganizationId = orgNode
        ? getOrganizationIdFromOrgNode(orgNode, BHF_ORGANIZATION_NAME)
        : null;

    const axiaOrChildOrganizationId = orgNode
        ? getOrganizationIdFromOrgNode(orgNode, AXIA_BRANDING_NAME)
        : null;

    const htcHaOrganizationId = orgNode
        ? getOrganizationIdFromOrgNode(orgNode, HTC_HA_BRANDING_NAME)
        : null;

    const progenyHealthOrganizationId = orgNode
        ? getOrganizationIdFromOrgNode(orgNode, PROGENY_HEALTH_BRANDING_NAME)
        : null;

    const WhasnOrganizationId = orgNode
        ? getOrganizationIdFromOrgNode(orgNode, WHASN_ORGANIZATION_NAME)
        : null;

    const stPetersOrganizationId = orgNode
        ? getOrganizationIdFromOrgNode(orgNode, ST_PETERS_BRANDING_NAME)
        : null;
    const isHtcOrChildrenSelected = htcOrChildOrganizationId
        ? orgState[htcOrChildOrganizationId]
        : isCurrentPracticeChildOfCustomOrg(ancestorsBrandingNames, HTC_ORGANIZATION_NAME);

    const isBhfOrChildrenSelected = BhfOrChildOrganizationId
        ? orgState[BhfOrChildOrganizationId]
        : isCurrentPracticeChildOfCustomOrg(ancestorsBrandingNames, BHF_ORGANIZATION_NAME);

    const isAxiaOrChildrenSelected = axiaOrChildOrganizationId
        ? orgState[axiaOrChildOrganizationId]
        : isCurrentPracticeChildOfCustomOrg(ancestorsBrandingNames, AXIA_BRANDING_NAME);

    const isHtcHealthAdvocateSelected = htcHaOrganizationId
        ? orgState[htcHaOrganizationId]
        : isCurrentPracticeChildOfCustomOrg(ancestorsBrandingNames, HTC_HA_BRANDING_NAME);

    const isProgenyHealthOrChildSelected = progenyHealthOrganizationId
        ? orgState[progenyHealthOrganizationId]
        : isCurrentPracticeChildOfCustomOrg(ancestorsBrandingNames, PROGENY_HEALTH_BRANDING_NAME);
    const isWHASNOrChildrenSelected = WhasnOrganizationId
        ? orgState[WhasnOrganizationId]
        : isCurrentPracticeChildOfCustomOrg(ancestorsBrandingNames, WHASN_ORGANIZATION_NAME);
    const isStPetersOrChildSelected = stPetersOrganizationId
        ? orgState[stPetersOrganizationId]
        : isCurrentPracticeChildOfCustomOrg(ancestorsBrandingNames, ST_PETERS_BRANDING_NAME);

    const {
        htcBillingDataReport,
        bhfMonthlyInvoicing,
        patientListExportForAxia,
        htcGenericDataReport,
        allPatientList,
        pregnancyPatientList,
        highRiskEPDSReport,
        progenyBillingReport,
        sshpCaseReport,
        stPetersBillingReport,
        hpnSupplementalDataFeedPregnant,
        hpnSupplementalDataFeedPostpartum,
        ...commonItems
    } = reportingListOptions;

    const restItems = { ...commonItems, allPatientList };
    const shouldShowRestItems = !isBhfOrChildrenSelected;

    return {
        ...(shouldShowRestItems ? restItems : { allPatientList }),
        ...(isBhfOrChildrenSelected ? { bhfMonthlyInvoicing, highRiskEPDSReport } : {}),
        ...(isHtcOrChildrenSelected ? { htcBillingDataReport, pregnancyPatientList } : {}),
        ...(isAxiaOrChildrenSelected ? { patientListExportForAxia } : {}),
        ...(isHtcHealthAdvocateSelected ? { htcGenericDataReport } : {}),
        ...(isProgenyHealthOrChildSelected ? { progenyBillingReport } : {}),
        ...(isWHASNOrChildrenSelected
            ? { sshpCaseReport, hpnSupplementalDataFeedPregnant, hpnSupplementalDataFeedPostpartum }
            : {}),
        ...(isStPetersOrChildSelected ? { stPetersBillingReport } : {}),
    };
};

const getStartDateByMonthAndYear = (monthDate: Moment, yearDate: Moment) => {
    return moment().month(monthDate.month()).year(yearDate.year()).date(1);
};

const getEndDateByMonthAndYear = (monthDate: Moment, yearDate: Moment) => {
    const lastDayOfMonth = moment().month(monthDate.month()).year(yearDate.year()).daysInMonth();
    return moment().month(monthDate.month()).year(yearDate.year()).date(lastDayOfMonth);
};

export const getDatesForReport = (
    startDate: Moment | undefined,
    endDate: Moment | undefined,
    monthDate: Moment | undefined,
    yearDate: Moment | undefined,
    isByMonth: boolean,
    isReportByWeek: boolean,
    reportWeekDate: Moment | undefined,
): { reportStartDate: string | undefined; reportEndDate: string | undefined } => {
    if (isReportByWeek && reportWeekDate) {
        return {
            reportStartDate: reportWeekDate.startOf('day').toString(),
            reportEndDate: reportWeekDate.clone().add(6, 'd').endOf('day').toString(),
        };
    }
    if (isByMonth && monthDate && yearDate) {
        return {
            reportStartDate: getStartDateByMonthAndYear(monthDate, yearDate)
                .startOf('day')
                .toString(),
            reportEndDate: getEndDateByMonthAndYear(monthDate, yearDate).endOf('day').toString(),
        };
    }

    if (startDate && endDate) {
        return {
            reportStartDate: startDate.startOf('day').toString(),
            reportEndDate: endDate.endOf('day').toString(),
        };
    }

    return {
        reportStartDate: undefined,
        reportEndDate: undefined,
    };
};

export const getReportNameForReportingJob = (
    list: any,
    option: string,
): ReportingJobReportNameType | undefined => {
    return Object.keys(list).find(key => list[key] === option) as ReportingJobReportNameType;
};

import {
    Autocomplete,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    Grid,
    TextField,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { FetchAdvocatesForAdvocateTaskListPageQueryQuery } from '~/schemaTypes';
import _ from 'lodash';
import { UNASSIGNED } from './AdvocateTaskList';

const useStyles = makeStyles()({
    dialogActions: {
        '& .leftButtons': {
            width: '50%',
            textAlign: 'left',
        },
        '& .rigthButtons': {
            width: '50%',
            textAlign: 'right',
        },
        '> div > button': {
            margin: '0 5px',
        },
    },
});

interface AssignToFormInput {
    assignToId: string;
}

type ProviderModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    advocates?: FetchAdvocatesForAdvocateTaskListPageQueryQuery;
    reassignHandler: (id: string) => void;
    isLoading: boolean;
};

const AdvocateTasksAssignModal: React.FC<ProviderModalProps> = ({
    setOpen,
    advocates,
    reassignHandler,
    isLoading,
}) => {
    const { classes } = useStyles();
    const { control, handleSubmit } = useForm<AssignToFormInput>({});

    const [assignedToId, setAssignedToId] = useState<string>('');

    const advocateList = () => {
        const sorted = advocates?.getUsersByPermission
            ? _.sortBy(advocates?.getUsersByPermission, 'name').map(a => ({
                  id: a?.id || '',
                  name: a?.name || '',
              }))
            : [];

        return [{ id: UNASSIGNED, name: 'Unassigned' }].concat(sorted);
    };

    const onSubmit = ({ assignToId }: AssignToFormInput) => {
        reassignHandler(assignToId);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleClear = () => {
        setAssignedToId('');
    };

    return (
        <>
            <DialogTitleWithClose onClose={handleCancel} id="Assign To">
                Assign To
            </DialogTitleWithClose>
            <DialogContent dividers>
                {isLoading ? (
                    <Grid container justifyContent="center">
                        <CircularProgress />
                    </Grid>
                ) : (
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            <Controller
                                name="assignToId"
                                defaultValue=""
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        sx={{ width: '100%' }}
                                        value={
                                            advocateList().find(i => i?.id === assignedToId) || null
                                        }
                                        onChange={(_, value) => {
                                            setAssignedToId(value?.id || '');
                                            field.onChange(value?.id);
                                        }}
                                        options={advocateList()}
                                        getOptionLabel={option =>
                                            advocateList().find(i => i?.id === option?.id)?.name ??
                                            ''
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                            option?.id === value?.id
                                        }
                                        renderInput={params => (
                                            <TextField
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                {...params}
                                                label="Assigned to"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                    </form>
                )}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                {!isLoading && (
                    <>
                        <div className="leftButtons">
                            <Button onClick={handleClear} color="warning" variant="contained">
                                Clear value
                            </Button>
                        </div>
                        <div className="rigthButtons">
                            <Button onClick={handleCancel} color="primary" variant="contained">
                                Cancel
                            </Button>
                            <Button
                                onClick={() => handleSubmit(onSubmit)()}
                                variant="contained"
                                color="secondary"
                                disabled={assignedToId === ''}
                            >
                                Save
                            </Button>
                        </div>
                    </>
                )}
            </DialogActions>
        </>
    );
};

export default AdvocateTasksAssignModal;

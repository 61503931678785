import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Add } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    ArticleExportListDocument,
    ArticleExportListQuery,
    useArticleExportListQuery,
    useDeleteArticleExportMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import Loading from '~/components/Loading/Loading';
import { Dialog } from '@mui/material';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { NavLink } from 'react-router-dom';
import { useStyles } from './styles';
import ExportArticlesModal from './components/ExportArticlesModal';

type ArticleExport = NonNullable<ArticleExportListQuery['articleExports'][0]>;
const ARTICLE_EXPORT_URL = 'https://dmbryysnxjbx5.cloudfront.net/articleExport';

export interface ExportParms {
    closeHandler: () => void;
}

const ArticleExportList: React.FC = () => {
    const { classes } = useStyles();
    const { data, loading } = useArticleExportListQuery();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedArticleExport, setSelectedArticleExport] = useState<ArticleExport | null>(null);
    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [deleteItem, { loading: deleteItemLoading }] = useDeleteArticleExportMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: ArticleExportListDocument,
                variables: {},
            },
        ],
    });
    const handleDelete = (id: string) => {
        // eslint-disable-next-line no-restricted-globals, no-alert
        TriggerGlobalConfirm({
            message: `Do you really want to delete this Export'?`,
            callback: () => {
                deleteItem({ variables: { input: { id } } });
            },
        });
    };
    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const handleClose = () => {
        setModalOpen(false);
    };

    if (loading || deleteItemLoading) {
        return <Loading />;
    }
    return (
        <>
            <div className={classes.root} ref={tableRef}>
                <MaterialTable<ArticleExport>
                    title="Article Exports"
                    icons={tableIcons}
                    isLoading={loading || deleteItemLoading}
                    data={_.cloneDeep(data?.articleExports) ?? []}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    actions={[
                        {
                            onClick: () => {
                                setSelectedArticleExport(null);
                                setModalOpen(true);
                            },
                            icon: () => <Add />,
                            tooltip: 'Create Article Export',
                            isFreeAction: true,
                            hidden: !pagePermissions?.ArticleExport.Edit,
                        },
                        rowData => ({
                            onClick: (_, item) => {
                                const isSingleItemSelected = !Array.isArray(item);
                                if (isSingleItemSelected) {
                                    handleDelete(item.id);
                                }
                            },
                            icon: () => <FontAwesomeIcon icon={faTrash} />,
                            tooltip: 'Delete Article Export',
                            hidden: !pagePermissions?.ArticleExport.Delete || !rowData.completedAt,
                        }),
                    ]}
                    columns={[
                        {
                            title: 'Name',
                            field: 'name',
                        },
                        {
                            title: 'App',
                            field: 'appBundleId',
                        },
                        {
                            title: 'Language',
                            field: 'language',
                            emptyValue: 'All',
                        },
                        {
                            title: 'Reading Level',
                            field: 'readingLevel',
                            emptyValue: 'All',
                        },
                        {
                            title: 'Groups',
                            field: 'groupNames',
                            emptyValue: 'All',
                        },
                        {
                            title: 'Categories',
                            field: 'categoryNames',
                            emptyValue: 'All',
                        },
                        {
                            title: 'Submitted',
                            field: 'submittedAt',
                            emptyValue: 'Not Submitted',
                        },
                        {
                            title: 'File Link',
                            render: ({ id, completedAt }) => {
                                if (completedAt) {
                                    return (
                                        <NavLink
                                            style={{ marginRight: '10px' }}
                                            key={id}
                                            to={`${ARTICLE_EXPORT_URL}/${id}.xlsx`}
                                        >
                                            {`${id}.xlsx`}
                                        </NavLink>
                                    );
                                }
                                return <div>Not Completed</div>;
                            },
                        },
                    ]}
                    options={{ pageSize: rowsPerPage }}
                    localization={{ header: { actions: '' } }}
                />
            </div>
            {modalOpen && (
                <Dialog open={modalOpen}>
                    <DialogTitleWithClose id="dialogTitle" onClose={handleClose}>
                        {selectedArticleExport ? 'Edit Article Export' : 'Create Article Export'}
                    </DialogTitleWithClose>
                    <ExportArticlesModal closeHandler={handleClose} />
                </Dialog>
            )}
        </>
    );
};

export default ArticleExportList;

import { toInteger } from 'lodash';
import React, { useEffect, useState } from 'react';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';

interface DateEditorParams {
    setSelectedDate: (selectedDate: string) => void;
    title: string;
    initialDate?: string | null;
}

const DateEditor: React.FC<DateEditorParams> = ({ setSelectedDate, initialDate, title }) => {
    const today = new Date();
    const [selectedYear, setSelectedYear] = useState<number>(today.getFullYear());
    const [selectedMonth, setSelectedMonth] = useState<number>(today.getMonth() + 1);
    const [selectedDay, setSelectedDay] = useState<number>(today.getDate());
    const availableYears = Array.from({ length: 80 }, (_, i) => i + (today.getFullYear() - 79));
    const availableMonths = Array.from({ length: 12 }, (_, i) => i + 1);
    const [availableDays, setAvailableDays] = useState<number[]>([]);
    const setDays = (year: number, month: number) => {
        let days = Array.from({ length: 28 }, (_, i) => i + 1);
        if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
            days = days.concat([29, 30, 31]);
        } else if ([4, 6, 9, 11].includes(month)) {
            days = days.concat([29, 30]);
        } else if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
            days = days.concat(29);
        }
        setAvailableDays(days);
    };
    useEffect(() => {
        if (initialDate) {
            const year = toInteger(initialDate.substring(0, 4));
            const month = toInteger(initialDate.substring(5, 7));
            const day = toInteger(initialDate.substring(8, 10));
            setSelectedYear(year);
            setSelectedMonth(month);
            setSelectedDay(day);
            setDays(year, month);
        }
    }, [initialDate]);
    const onChangeYear = (newYear: number) => {
        const paddedDay = selectedDay.toString().padStart(2, '0');
        const paddedMonth = selectedMonth.toString().padStart(2, '0');
        setSelectedYear(newYear);
        setSelectedDate(`${newYear}-${paddedMonth}-${paddedDay}T00:00:00.000Z`);
        setDays(newYear, selectedMonth);
    };
    const onChangeMonth = (newMonth: number) => {
        const paddedDay = selectedDay.toString().padStart(2, '0');
        const paddedMonth = newMonth.toString().padStart(2, '0');
        setSelectedMonth(newMonth);
        setSelectedDate(`${selectedYear}-${paddedMonth}-${paddedDay}T00:00:00.000Z`);
        setDays(selectedYear, newMonth);
    };
    const onChangeDay = (newDay: number) => {
        const paddedDay = newDay.toString().padStart(2, '0');
        const paddedMonth = selectedMonth.toString().padStart(2, '0');
        setSelectedDay(newDay);
        setSelectedDate(`${selectedYear}-${paddedMonth}-${paddedDay}T00:00:00.000Z`);
    };

    return (
        <OutlinedSection title={title}>
            Year:
            <select
                style={{ marginLeft: '5px', marginRight: '15px' }}
                value={selectedYear}
                onChange={e => onChangeYear(toInteger(e.target.value))}
            >
                {availableYears.map(y => (
                    <option value={y} key={y}>
                        {y}
                    </option>
                ))}
            </select>
            Month:
            <select
                style={{ marginLeft: '5px', marginRight: '15px' }}
                value={selectedMonth}
                onChange={e => onChangeMonth(toInteger(e.target.value))}
            >
                {availableMonths.map(m => (
                    <option value={m} key={m}>
                        {m}
                    </option>
                ))}
            </select>
            Day:
            <select
                style={{ marginLeft: '5px' }}
                value={selectedDay}
                onChange={e => onChangeDay(toInteger(e.target.value))}
            >
                {availableDays.map(d => (
                    <option value={d} key={d}>
                        {d}
                    </option>
                ))}
            </select>
        </OutlinedSection>
    );
};
export default DateEditor;

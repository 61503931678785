// This component has a UI Test
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import { Dialog, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { PAGESIZE } from '~/constants';
import { lowercaseFirstLetter } from '~/helpers/lowercaseFirstLetter';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import { FetchConfigAppForConfigAppSummaryPageQuery } from '~/schemaTypes';
import { AppValueEnum } from '~/selectors';

import AppSummaryValueModal from './AppSummaryValuesModal/AppSummaryValuesModal';

type AMSApp = NonNullable<FetchConfigAppForConfigAppSummaryPageQuery['amsAppByBundleId']>;
type ConfigValueDef = NonNullable<
    FetchConfigAppForConfigAppSummaryPageQuery['amsAppByBundleId']
>['defaultValues'][0];
type ConfigValue = NonNullable<
    FetchConfigAppForConfigAppSummaryPageQuery['amsAppByBundleId']
>['values'][0];

const useStyles = makeStyles()({
    root: {},
});

type ConfigAppSummaryValuesProps = {
    values: ConfigValue[];
    valueDefs: ConfigValueDef[];
    app: AMSApp;
};

const ConfigAppSummaryValues: React.FC<ConfigAppSummaryValuesProps> = ({
    values,
    valueDefs,
    app,
}) => {
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const [editAppValueDef, setEditAppValueDef] = useState<ConfigValueDef>(valueDefs[0]);
    const [editAppValue, setEditAppValue] = useState<ConfigValue | null>(values[0]);
    const [showAppValueModal, setShowAppValueModal] = useState<boolean>(false);
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);

    const combinedAppValues = useMemo(() => {
        return valueDefs.map(def => {
            const value = values.find(value => value.valueDef.id === def.id);
            return {
                layer: value != null ? 'Value Override' : 'Default',
                valueOverride: value,
                ...def,
            };
        });
    }, [valueDefs, values]);

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <div className={classes.root} data-test={AppValueEnum.APP_VALUES}>
            <MaterialTable
                icons={tableIcons as MaterialTableProps<any>['icons']}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                actions={[
                    {
                        onClick: (_, appValue: any) => {
                            const value = values.find(
                                ({ valueDef }) => appValue.id === valueDef.id,
                            );
                            setEditAppValue(value ?? null);
                            setEditAppValueDef(appValue);
                            setShowAppValueModal(true);
                        },
                        disabled: !pagePermissions?.AppDefaults.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit App Value',
                    },
                ]}
                columns={[
                    { title: 'Name', field: 'name', defaultSort: 'asc' },
                    {
                        title: 'Current Value',
                        field: 'value',
                        render: ({ valueOverride, defaultValue, valueType }) => {
                            const displayValue = valueOverride?.value ?? defaultValue;
                            return (
                                <Typography>
                                    {JSON.stringify(displayValue[lowercaseFirstLetter(valueType)])
                                        .replaceAll('"', '')
                                        .replaceAll('[', '')
                                        .replaceAll(']', '')
                                        .replace(/__typename[^,]*,/, '')
                                        .replace(/,/g, ', ')}
                                </Typography>
                            );
                        },
                    },
                    {
                        title: 'Value Type',
                        field: 'valueType',
                    },
                    { title: 'Layer', field: 'layer' },
                    { title: 'Description', field: 'description' },
                ]}
                data={_.cloneDeep(combinedAppValues)}
                title="App Values"
                localization={{ header: { actions: '' } }}
                options={{ pageSize: rowsPerPage }}
            />
            <Dialog
                scroll="paper"
                open={showAppValueModal}
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                <AppSummaryValueModal
                    setOpen={setShowAppValueModal}
                    configValueDef={editAppValueDef}
                    configValue={editAppValue}
                    appBundleId={app.bundleId}
                />
            </Dialog>
        </div>
    );
};

export default ConfigAppSummaryValues;

import { Button, Checkbox, TextField } from '@mui/material';
import { Save } from '@mui/icons-material';
import React, { useState } from 'react';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import {
    useCreateBatchTaskMutation,
    FetchBatchTaskListDocument,
    BatchTaskInput,
    BatchTaskStatus,
    BatchTaskType,
    useFetchAppsTagsForBatchTasksQuery,
    AmsProduct,
    Language,
    ReadingLevel,
    useArticleGroupListQuery,
    useArticleCategoryListQuery,
    useCreateArticleExportMutation,
    ArticleExportListQuery,
    ArticleExportListDocument,
} from '~/schemaTypes';
import { useUser } from '~/hooks';
import _ from 'lodash';
import Loading from '~/components/Loading/Loading';
import { TriggerGlobalConfirm } from '~/state';
import { ExportParms } from '../ArticleExportLists';

const ExportArticlesModal: React.FC<ExportParms> = ({ closeHandler }) => {
    const { data: userData } = useUser();
    const { data: prefetchData, loading: prefetchDataLoading } =
        useFetchAppsTagsForBatchTasksQuery();
    const { data: groupList, loading: groupListLoading } = useArticleGroupListQuery({});
    const { data: categoryList, loading: categoryListLoading } = useArticleCategoryListQuery({});
    const [createBatchTask] = useCreateBatchTaskMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: FetchBatchTaskListDocument,
                variables: {},
            },
        ],
    });

    const [createArticleExport, { loading: createLoading }] = useCreateArticleExportMutation({
        onCompleted: ({ createArticleExport }) => {
            const data: BatchTaskInput = {
                type: BatchTaskType.ArticleExport,
                status: BatchTaskStatus.NotStarted,
                addedBy: userData?.currentUser?.name ?? '',
                priority: 5,
                runAfter: new Date(),
                settings: {
                    articleExportId: createArticleExport?.resourceCreated?.id,
                },
            };
            createBatchTask({
                variables: {
                    input: data,
                },
            });
            closeHandler();
        },
        onError: error => {
            TriggerGlobalConfirm({
                callback: () => {
                    closeHandler();
                },
                message: `There was a problem saving the article export: ${error.message}`,
            });
        },
        update: (cache, response) => {
            const newItem = response.data?.createArticleExport?.resourceCreated;
            if (response.data?.createArticleExport?.success && newItem) {
                const currentItems = cache.readQuery<ArticleExportListQuery>({
                    query: ArticleExportListDocument,
                });
                if (currentItems?.articleExports) {
                    cache.writeQuery<ArticleExportListQuery>({
                        query: ArticleExportListDocument,
                        data: {
                            articleExports: [...currentItems.articleExports, newItem],
                        },
                    });
                }
            }
        },
    });

    const [appBundleId, setAppBundleId] = useState('');
    const [catIds, setCatIds] = useState<string[]>([]);
    const [groups, setGroups] = useState<string[]>([]);
    const [categoryNames, setCatNames] = useState<string[]>([]);
    const [groupNames, setGroupNames] = useState<string[]>([]);
    const [language, setLanguage] = useState<Language | undefined>();
    const [readingLevel, setReadingLevel] = useState<ReadingLevel | undefined>();
    const [name, setName] = useState<string>('');
    const onSubmit = () => {
        createArticleExport({
            variables: {
                input: {
                    name,
                    appBundleId,
                    categoryIds: catIds,
                    groupIds: groups,
                    language,
                    readingLevel,
                    submittedAt: new Date(),
                    groupNames,
                    categoryNames,
                },
            },
        });
    };
    const catSelected = (selected: string, name: string, checked: boolean) => {
        if (checked) {
            setCatIds(catIds.concat(selected));
            setCatNames(categoryNames.concat(name));
        } else {
            setCatIds(catIds.filter(c => c !== selected));
            setCatNames(categoryNames.filter(c => c !== name));
        }
    };
    const groupSelected = (selected: string, name: string, checked: boolean) => {
        if (checked) {
            setGroups(groups.concat(selected));
            setGroupNames(groupNames.concat(name));
        } else {
            setGroups(groups.filter(g => g !== selected));
            setGroupNames(groupNames.filter(c => c !== name));
        }
    };
    if (prefetchDataLoading || groupListLoading || categoryListLoading || createLoading) {
        return <Loading />;
    }
    return (
        <>
            <OutlinedSection title="Export Selections">
                <OutlinedSection title="Article Export Name *">
                    <TextField
                        variant="outlined"
                        type="text"
                        fullWidth
                        margin="dense"
                        defaultValue={name}
                        onChange={e => setName(e.target.value)}
                    />
                </OutlinedSection>
                <OutlinedSection title="App *">
                    <select
                        value={appBundleId}
                        onChange={e => {
                            setAppBundleId(e.target.value);
                        }}
                    >
                        <option value="">Select App...</option>
                        {_.sortBy(prefetchData?.amsApps, 'bundleId')
                            .filter(a => a.productConst === AmsProduct.VbcPlatform)
                            .map(a => (
                                <option value={a.bundleId}>{a.bundleId}</option>
                            ))}
                    </select>
                </OutlinedSection>
                <OutlinedSection title="Language">
                    <select
                        value={language}
                        onChange={e => {
                            setLanguage(e.target.value as Language);
                        }}
                    >
                        <option value="">Any Language</option>
                        <option value={Language.En}>English</option>
                        <option value={Language.Es}>Spanish</option>
                    </select>
                </OutlinedSection>
                <OutlinedSection title="Reading Level">
                    <select
                        value={readingLevel}
                        onChange={e => {
                            setReadingLevel(e.target.value as ReadingLevel);
                        }}
                    >
                        <option value="">Any Reading Level</option>
                        <option value={ReadingLevel.Eighth}>Eighth</option>
                        <option value={ReadingLevel.Fifth}>Fifth</option>
                    </select>
                </OutlinedSection>
                <OutlinedSection title="Groups">
                    {groupList &&
                        _.sortBy(groupList.articleGroups, 'name').map(g => (
                            <span style={{ marginLeft: '10px' }}>
                                {g.name}
                                <Checkbox
                                    value={g.id}
                                    key={g.id}
                                    checked={groups.find(grp => grp === g.id) !== undefined}
                                    onChange={e =>
                                        groupSelected(e.target.value, g.name, e.target.checked)
                                    }
                                />
                            </span>
                        ))}
                </OutlinedSection>
                <OutlinedSection title="Categories">
                    {categoryList &&
                        _.sortBy(categoryList.articleCategorys, 'name').map(c => (
                            <span style={{ marginLeft: '10px' }}>
                                {c.name}
                                <Checkbox
                                    value={c.id}
                                    key={c.id}
                                    checked={catIds.find(cat => cat === c.id) !== undefined}
                                    onChange={e =>
                                        catSelected(e.target.value, c.name, e.target.checked)
                                    }
                                />
                            </span>
                        ))}
                </OutlinedSection>
            </OutlinedSection>
            <div style={{ width: '100%', textAlign: 'right' }}>
                <Button
                    type="submit"
                    startIcon={<Save />}
                    color="secondary"
                    variant="contained"
                    onClick={onSubmit}
                    disabled={!appBundleId}
                >
                    Save & Submit
                </Button>
            </div>
        </>
    );
};

export default ExportArticlesModal;

import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { Save } from '@mui/icons-material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, UseFieldArrayReturn, useFieldArray, useForm } from 'react-hook-form';
import Loading from '~/components/Loading/Loading';
import { displayDate, displayDateLocale } from '~/helpers';
import {
    FetchChoicesForProfileDefinitionByNameQuery,
    FetchPatientProfileVariablesDocument,
    UpdatePatientProfileVariableMutation,
    useFetchChoicesForProfileDefinitionByNameLazyQuery,
    useFetchProfileValueOriginListForPatientQuery,
    useUpdatePatientProfileVariableMutation,
    ProfileValueType,
    AlertSeverity,
} from '~/schemaTypes';
import _ from 'lodash';
import Mappers from '~/helpers/mappers';
import { LBS } from '~/components/WeightValue/WeightValue';
import { TriggerGlobalAlert } from '~/state';
import useCareTeamMembers, { CareTeamMember } from '../../../../../../hooks/useCareTeamMembers';
import { PROFILE_VALUES_TO_CONVERT_WEIGHT } from '../PatientProfileVariables';
import { AddAnotherValue } from '../shared/components';
import { UserProfileValueType } from '../shared/enums';
import {
    ChoiceValueType,
    FetchPatientProfileVariablesQuery,
    FetchPatientProfileVariablesQueryVariables,
    PatientProfileVariable,
    PatientProfileVariableValueTypeToJSXElementType,
    SpecifiedValueTypeToJSXElementType,
} from '../shared/types';
import { toFormDefaultValues, toUpdateInput, toViewModelValue } from './mappers';
import useStyles from './styles';
import { getChoiceValue } from './utils';

interface PatientProfileVariableModalProps {
    patientId: string;
    appBundleId: string;
    systemGenerated?: boolean;
    patientProfileVariable: PatientProfileVariable;
    closeHandler: (data?: {
        updatePatientProfileVariableMutation?: UpdatePatientProfileVariableMutation;
        profileDefChoices?: FetchChoicesForProfileDefinitionByNameQuery;
    }) => void;
    viewOnly?: boolean;
}

const FormFields = {
    name: 'Name',
    description: 'Description',
    categoryName: 'Category',
    valueType: 'Value Type',
};
const converToKg = (num: number): number => {
    return num / LBS;
};

const SpecifiedValueTypeToJSXElement: SpecifiedValueTypeToJSXElementType = {
    [UserProfileValueType.Str]: ({ register, defaultValue, choiceId }: any) => (
        <TextField
            {...register(`value.specifiedValue.${choiceId}.value`)}
            defaultValue={defaultValue}
            fullWidth
            label="Value"
            variant="outlined"
            size="small"
        />
    ),
    [UserProfileValueType.Num]: ({ register, defaultValue, choiceId }: any) => (
        <TextField
            {...register(`value.specifiedValue.${choiceId}.value`)}
            defaultValue={defaultValue}
            fullWidth
            label="Value"
            variant="outlined"
            size="small"
            type="number"
        />
    ),
    [UserProfileValueType.Date]: ({ control, defaultValue, choiceId }: any) => (
        <Controller
            control={control}
            name={`value.specifiedValue.${choiceId}.value`}
            defaultValue={defaultValue}
            render={({ field: { value, onChange, ...field } }) => {
                const dateString = displayDate({ isoDateStr: value });
                let date = new Date();
                if (dateString) date = new Date(dateString);
                return (
                    <DesktopDatePicker
                        {...field}
                        label={value ? '' : 'Value'}
                        onChange={value => {
                            onChange(
                                displayDateLocale({
                                    isoDateStr: value?.toISOString() ?? new Date().toISOString(),
                                }),
                            );
                        }}
                        value={date}
                        format="MM/dd/yyyy"
                    />
                );
            }}
        />
    ),
    [UserProfileValueType.Bool]: ({ control, defaultValue, choiceId }: any) => (
        <Controller
            name={`value.specifiedValue.${choiceId}.value`}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { value, onChange } }) => (
                <FormControl variant="outlined">
                    <FormControlLabel
                        control={
                            <Checkbox onChange={e => onChange(e.target.checked)} checked={value} />
                        }
                        label="True/False"
                    />
                </FormControl>
            )}
        />
    ),
};

const PatientProfileVariableValueTypeToJSXElement: PatientProfileVariableValueTypeToJSXElementType =
    {
        [UserProfileValueType.Str]: ({ register, viewOnly }) => {
            return (
                <TextField
                    fullWidth
                    label="Value*"
                    variant="outlined"
                    size="small"
                    required
                    disabled={viewOnly}
                    {...register('value.str')}
                />
            );
        },
        [UserProfileValueType.Num]: ({ register, viewOnly }) => {
            return (
                <TextField
                    fullWidth
                    label="Value*"
                    variant="outlined"
                    size="small"
                    type="number"
                    required
                    disabled={viewOnly}
                    {...register('value.num')}
                />
            );
        },
        [UserProfileValueType.Strs]: ({ control, fieldArrayMethods, viewOnly }) => (
            <AddAnotherValue
                append={() => fieldArrayMethods?.append({ value: '' })}
                remove={index => fieldArrayMethods?.remove(index)}
                fields={fieldArrayMethods?.fields ?? []}
                name="value.strs"
                type="input"
                control={control}
                disabled={viewOnly}
            />
        ),
        [UserProfileValueType.Nums]: ({ control, fieldArrayMethods, viewOnly }) => (
            <AddAnotherValue
                append={() => fieldArrayMethods?.append({ value: '' })}
                remove={index => fieldArrayMethods?.remove(index)}
                fields={fieldArrayMethods?.fields ?? []}
                name="value.nums"
                type="input"
                control={control}
                inputProps={{
                    type: 'number',
                }}
                disabled={viewOnly}
            />
        ),
        [UserProfileValueType.Bool]: ({ control, viewModelValue, viewOnly }) => (
            <Controller
                name="value.bool"
                control={control}
                defaultValue={viewModelValue.bool}
                render={({ field: { value, onChange } }) => (
                    <FormControl variant="outlined">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={e => onChange(e.target.checked)}
                                    checked={value ?? undefined}
                                    disabled={viewOnly}
                                />
                            }
                            label="True/False"
                            style={{ flexBasis: '90%' }}
                        />
                    </FormControl>
                )}
            />
        ),
        [UserProfileValueType.Bools]: ({ control, fieldArrayMethods, viewOnly }) => (
            <AddAnotherValue
                append={() => fieldArrayMethods?.append({ value: false })}
                remove={index => fieldArrayMethods?.remove(index)}
                control={control}
                fields={fieldArrayMethods?.fields ?? []}
                name="value.bools"
                type="checkbox"
                disabled={viewOnly}
            />
        ),
        [UserProfileValueType.Date]: ({ control, viewOnly }) => (
            <Controller
                control={control}
                name="value.date"
                render={({ field: { value, onChange, ...field } }) => {
                    const dateString = displayDate({ isoDateStr: value });
                    let date = new Date();
                    if (dateString) date = new Date(dateString);
                    return (
                        <DesktopDatePicker
                            {...field}
                            label={value ? '' : 'Value'}
                            onChange={value => {
                                onChange(
                                    displayDateLocale({
                                        isoDateStr:
                                            value?.toISOString() ?? new Date().toISOString(),
                                    }),
                                );
                            }}
                            value={date}
                            format="MM/dd/yyyy"
                            disabled={viewOnly}
                        />
                    );
                }}
            />
        ),
        [UserProfileValueType.Dates]: ({ control, fieldArrayMethods, viewOnly }) => (
            <AddAnotherValue
                append={() => fieldArrayMethods?.append({ value: null })}
                remove={index => fieldArrayMethods?.remove(index)}
                control={control}
                fields={fieldArrayMethods?.fields ?? []}
                name="value.dates"
                type="date"
                disabled={viewOnly}
            />
        ),
        [UserProfileValueType.Choice]: ({
            control,
            viewModelValue,
            profileDefChoicesData,
            register,
            viewOnly,
        }) => (
            <Controller
                control={control}
                name="value.choice"
                defaultValue={viewModelValue.choice}
                render={({ field: { value, onChange } }) => (
                    <FormControl variant="outlined" fullWidth>
                        <Typography>Choices</Typography>
                        <RadioGroup value={value} onChange={onChange}>
                            <Grid container spacing={2} direction="column">
                                {profileDefChoicesData?.choices?.map(choice => {
                                    const choiceValueType =
                                        profileDefChoicesData?.choiceValueType as ChoiceValueType;
                                    const { id, specifyValue, label } = choice ?? {};

                                    if (!choiceValueType || !id) {
                                        return null;
                                    }

                                    const choiceValue = getChoiceValue(
                                        choice,
                                        choiceValueType,
                                        viewModelValue,
                                    );

                                    let valueElement = (
                                        <>
                                            <b>Value:</b> {choiceValue?.toString()}
                                        </>
                                    );

                                    if (specifyValue) {
                                        valueElement = SpecifiedValueTypeToJSXElement[
                                            choiceValueType
                                        ]({
                                            control,
                                            defaultValue: choiceValue,
                                            register,
                                            choiceId: id,
                                        });
                                    }

                                    return (
                                        <Grid item key={choice?.id}>
                                            <FormControlLabel
                                                value={choice?.id}
                                                control={<Radio />}
                                                disabled={viewOnly}
                                                label={
                                                    <>
                                                        <Typography>
                                                            <b>Label:</b> {label?.en ?? ''}
                                                        </Typography>
                                                        {valueElement}
                                                    </>
                                                }
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                )}
            />
        ),
        [UserProfileValueType.Choices]: ({
            control,
            profileDefChoicesData,
            viewModelValue,
            register,
            getValues,
            viewOnly,
        }) => (
            <FormControl variant="outlined" fullWidth>
                <Typography>Choices</Typography>
                <Grid container spacing={2} direction="column">
                    {profileDefChoicesData?.choices?.map(choice => {
                        const choiceValueType =
                            profileDefChoicesData?.choiceValueType as ChoiceValueType;
                        const { id, specifyValue, label } = choice ?? {};

                        if (!choiceValueType || !id) {
                            return null;
                        }

                        const choiceValue = getChoiceValue(choice, choiceValueType, viewModelValue);

                        let valueElement = (
                            <>
                                <b>Value:</b> {choiceValue}
                            </>
                        );

                        if (specifyValue) {
                            valueElement = SpecifiedValueTypeToJSXElement[choiceValueType]({
                                control,
                                defaultValue: choiceValue,
                                register,
                                choiceId: id,
                            });
                        }

                        return (
                            <Controller
                                key={choice?.id}
                                control={control}
                                name="value.choices"
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={value?.includes(choice?.id)}
                                                        value={choice?.id}
                                                        disabled={viewOnly}
                                                        onChange={({
                                                            target: { checked, value },
                                                        }) => {
                                                            const currentChoiceIds =
                                                                (getValues &&
                                                                    getValues('value.choices')) ??
                                                                [];
                                                            const currentChoiceIdsSet = new Set([
                                                                ...currentChoiceIds,
                                                            ]);

                                                            currentChoiceIdsSet[
                                                                checked ? 'add' : 'delete'
                                                            ](value);

                                                            onChange(
                                                                Array.from(currentChoiceIdsSet),
                                                            );
                                                        }}
                                                    />
                                                }
                                                label={
                                                    <>
                                                        <Typography>
                                                            <b>Label:</b> {label?.en ?? ''}
                                                        </Typography>
                                                        {valueElement}
                                                    </>
                                                }
                                            />
                                        </Grid>
                                    );
                                }}
                            />
                        );
                    })}
                </Grid>
            </FormControl>
        ),
        [UserProfileValueType.Localized]: ({ control, viewOnly }: any) => (
            <>
                <Controller
                    name="value.localized.en"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <TextField
                            value={value}
                            onChange={onChange}
                            fullWidth
                            label="Value En*"
                            variant="outlined"
                            size="small"
                            required
                            disabled={viewOnly}
                        />
                    )}
                />
                <Controller
                    name="value.localized.es"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <TextField
                            value={value}
                            onChange={onChange}
                            fullWidth
                            label="Value Es"
                            variant="outlined"
                            size="small"
                            disabled={viewOnly}
                        />
                    )}
                />
            </>
        ),
    };

const PatientProfileVariableModal: React.FC<PatientProfileVariableModalProps> = ({
    patientId,
    systemGenerated,
    patientProfileVariable,
    closeHandler,
    viewOnly,
    appBundleId,
}) => {
    const { classes } = useStyles();
    const defValues = toFormDefaultValues(patientProfileVariable);
    const { control, getValues, register, handleSubmit } = useForm<typeof defValues>({
        defaultValues: defValues,
    });
    const { valueType, value, name, originId, categoryName } = patientProfileVariable;
    const viewModelValue = toViewModelValue(value);
    const profileDefinitionInfo = useMemo(
        () => [{ category: categoryName, name }],
        [categoryName, name],
    );

    const { careTeamsMembersByTypes, isCareTeamProfileDefinition } = useCareTeamMembers({
        profileDefinitions: profileDefinitionInfo,
        bundleId: appBundleId,
    });

    const [
        loadProfileDefChoices,
        { loading: isProfileDefChoicesLoading, data: profileDefChoices },
    ] = useFetchChoicesForProfileDefinitionByNameLazyQuery({
        variables: { input: { fields: { name } } },
    });

    const { data: profileValueOriginList, loading: isProfileValueOriginListLoading } =
        useFetchProfileValueOriginListForPatientQuery();
    const [updatePatientProfileVariable, { loading: isPatientProfileVariableUpdating }] =
        useUpdatePatientProfileVariableMutation({
            onCompleted: (
                updatePatientProfileVariableMutation: UpdatePatientProfileVariableMutation,
            ) => {
                if (updatePatientProfileVariableMutation.updatePatientProfileVariable === null) {
                    TriggerGlobalAlert({
                        severity: AlertSeverity.Warning,
                        message: `${patientProfileVariable.name} has not been set`,
                    });
                }

                closeHandler({ updatePatientProfileVariableMutation, profileDefChoices });
            },
            optimisticResponse: {
                updatePatientProfileVariable: {
                    __typename: 'PatientProfileVariable',
                    profileVariableDefId: patientProfileVariable.profileVariableDefId,
                    name: patientProfileVariable.name,
                    valueType: patientProfileVariable.valueType,
                    categoryName: patientProfileVariable.categoryName,
                    conditionId: patientProfileVariable.conditionId,
                    conditionName: patientProfileVariable.conditionName,
                    episodeStartDate: patientProfileVariable.episodeStartDate,
                    description: patientProfileVariable.description,
                    systemGenerated: patientProfileVariable.systemGenerated,
                    haPermission: patientProfileVariable.haPermission,
                    value: patientProfileVariable.value,
                    stringifiedValue: patientProfileVariable.stringifiedValue,
                    originId: patientProfileVariable.originId,
                    notes: patientProfileVariable.notes,
                },
            },
            update: (cache, { data }) => {
                let { updatePatientProfileVariable: updatedPatientProfileVariable } = data || {};
                const currentPatientProfileVariables = cache.readQuery<
                    FetchPatientProfileVariablesQuery,
                    FetchPatientProfileVariablesQueryVariables
                >({
                    query: FetchPatientProfileVariablesDocument,
                    variables: { patientId, systemGenerated },
                });

                if (
                    currentPatientProfileVariables?.patientProfileVariables &&
                    updatedPatientProfileVariable
                ) {
                    if (updatedPatientProfileVariable.value.date) {
                        const dateString = displayDate({
                            isoDateStr: updatedPatientProfileVariable.value.date,
                        });
                        updatedPatientProfileVariable = {
                            ...updatedPatientProfileVariable,
                            stringifiedValue: dateString,
                            value: {
                                ...updatedPatientProfileVariable.value,
                                date: updatedPatientProfileVariable.value.date,
                            },
                        };
                    }
                    cache.writeQuery({
                        query: FetchPatientProfileVariablesDocument,
                        variables: { patientId, systemGenerated },
                        data: {
                            patientProfileVariables:
                                currentPatientProfileVariables.patientProfileVariables.map(v =>
                                    v.profileVariableDefId ===
                                    updatedPatientProfileVariable?.profileVariableDefId
                                        ? updatedPatientProfileVariable
                                        : v,
                                ),
                        },
                    });
                }
            },
            refetchQueries: ['ProfileKeys'],
        });

    const handleFormSubmit = useCallback(
        (formData: typeof defValues) => {
            const { name } = patientProfileVariable;
            // all the numeric values are stored in database accordingly to The International System of Units standards
            // some values had to be converted back and forth from kg to lbs
            if (PROFILE_VALUES_TO_CONVERT_WEIGHT.has(name)) {
                formData = {
                    ...formData,
                    value: {
                        ...formData.value,
                        num: formData.value.num ? converToKg(formData.value.num) : null,
                    },
                };
            }
            updatePatientProfileVariable({
                variables: {
                    input: toUpdateInput(
                        {
                            patientId,
                            patientProfileVariable,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            choiceValueType: profileDefChoices?.userProfileDef?.choiceValueType,
                        },
                        formData,
                    ),
                },
            });
        },
        [patientId, patientProfileVariable, profileDefChoices, updatePatientProfileVariable],
    );

    const handleClearValue = () => {
        if (patientProfileVariable) {
            const value = {
                ...Mappers.excludeTypeName(patientProfileVariable.value),
                [patientProfileVariable.valueType]:
                    patientProfileVariable.valueType === ProfileValueType.Choices ? [] : undefined,
            };

            updatePatientProfileVariable({
                variables: {
                    input: {
                        portalPatientId: patientId ?? '',
                        profileVariableDefId: patientProfileVariable.profileVariableDefId,
                        value,
                    },
                },
            });
        }
    };

    let fieldArrayMethods: UseFieldArrayReturn<typeof defValues, any> | null = null;

    if (
        (
            [
                UserProfileValueType.Strs,
                UserProfileValueType.Bools,
                UserProfileValueType.Nums,
                UserProfileValueType.Dates,
            ] as string[]
        ).includes(valueType)
    ) {
        // It was made intentionally, since it can display one data type in a time. There won't
        // be case where you have to display simultaneously two types, for instance dates[] and str.
        // eslint-disable-next-line react-hooks/rules-of-hooks
        fieldArrayMethods = useFieldArray({
            control,
            name: `value.${valueType}`,
        });
    }

    useEffect(() => {
        if (
            valueType === UserProfileValueType.Choice ||
            valueType === UserProfileValueType.Choices
        ) {
            loadProfileDefChoices();
        }
    }, [loadProfileDefChoices, valueType]);

    if (
        isProfileDefChoicesLoading ||
        isPatientProfileVariableUpdating ||
        isProfileValueOriginListLoading
    )
        return <Loading />;

    const isCareTeamTypeElement = isCareTeamProfileDefinition({
        category: patientProfileVariable.categoryName,
        name: patientProfileVariable.name,
    });

    const careTeamMembers =
        careTeamsMembersByTypes
            ?.find(
                item =>
                    item.careTeamType?.profileDefId === patientProfileVariable.profileVariableDefId,
            )
            ?.careTeamMembers?.filter(({ isActive }) => isActive !== false) ?? [];
    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid container direction="column" spacing={2} className={classes.root}>
                {Object.entries(FormFields).map(([key, value]) => (
                    <Grid item key={key}>
                        <TextField
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            defaultValue={patientProfileVariable[key]}
                            fullWidth
                            label={value}
                            variant="outlined"
                            size="small"
                            disabled
                        />
                    </Grid>
                ))}
                <Grid item>
                    {isCareTeamTypeElement && (
                        <Controller
                            control={control}
                            name="value.str"
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete<CareTeamMember, false>
                                    size="small"
                                    onChange={(_, val) => {
                                        onChange(val?.id);
                                    }}
                                    getOptionLabel={selected => selected?.label?.en ?? ''}
                                    isOptionEqualToValue={(option, val) => option?.id === val?.id}
                                    options={careTeamMembers}
                                    value={
                                        careTeamMembers.find(member => member.id === value) ?? null
                                    }
                                    renderInput={params => (
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            required
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...params}
                                            placeholder={`Select ${patientProfileVariable?.name}`}
                                            label={patientProfileVariable?.name}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            )}
                        />
                    )}
                    {!isCareTeamTypeElement &&
                        PatientProfileVariableValueTypeToJSXElement[valueType]({
                            control,
                            viewModelValue,
                            register,
                            getValues,
                            fieldArrayMethods,
                            profileDefChoicesData: profileDefChoices?.userProfileDef,
                            viewOnly,
                        })}
                </Grid>
                <Grid item>
                    <Controller
                        name="originId"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Box>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel variant="outlined" aria-label="origin">
                                        Origin
                                    </InputLabel>
                                    <Select
                                        variant="outlined"
                                        value={value}
                                        label="Origin"
                                        disabled={viewOnly}
                                        onChange={onChange}
                                        defaultValue={originId}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {_.sortBy(
                                            profileValueOriginList?.profileValueOrigins,
                                            'label.en',
                                        )?.map(origin => (
                                            <MenuItem key={origin.id} value={origin.id}>
                                                {origin.label.en}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        )}
                    />
                </Grid>
                <Grid item>
                    <Controller
                        name="notes"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                variant="outlined"
                                multiline
                                rows={4}
                                label="Notes"
                                fullWidth
                                value={value}
                                onChange={onChange}
                                disabled={viewOnly}
                            />
                        )}
                    />
                </Grid>
                <Grid item container>
                    <div style={{ width: '50%', textAlign: 'left' }}>
                        {!viewOnly && (
                            <FormControl variant="outlined">
                                <Button
                                    color="warning"
                                    variant="contained"
                                    onClick={() => handleClearValue()}
                                >
                                    Clear Value
                                </Button>
                            </FormControl>
                        )}
                    </div>
                    <div style={{ width: '50%', textAlign: 'right' }}>
                        <FormControl variant="outlined">
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => closeHandler()}
                            >
                                {viewOnly ? 'Close' : 'Cancel'}
                            </Button>
                        </FormControl>{' '}
                        {!viewOnly && (
                            <FormControl variant="outlined">
                                <Button
                                    startIcon={<Save />}
                                    color="secondary"
                                    variant="contained"
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </FormControl>
                        )}
                    </div>
                </Grid>
            </Grid>
        </form>
    );
};

export default PatientProfileVariableModal;

import React, { useEffect, useRef } from 'react';
import Loading from '~/components/Loading/Loading';
import {
    AlertSeverity,
    ChatConversationsV2ForMessageCenterDocument,
    ChatConversationsV2ForMessageCenterQuery,
    ChatConversationsV2ForMessageCenterQueryVariables,
    OrderByDirectionEnum,
    useChatConversationsV2ForMessageCenterQuery,
    useMoveMessagesInChatConversationForMessageCenterMutation,
    useResolveChatConversationForMessageCenterMutation,
} from '~/schemaTypes';
import { Button, Grid } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import { TriggerGlobalAlert } from '~/state';
import { displayDateWithAbbrTimeZone } from '~/helpers';
import { motion, PanInfo } from 'framer-motion';
import { makeStyles } from 'tss-react/mui';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChatMessage from '../ChatMessage/ChatMessage';
import { ChatRoom, isChatConversations } from '../../MessageCenter.types';
import { conversationsLoadedAtom, draggedConversationIdAtom } from '../../MessageCenter.context';
import { getDraggedItemHelper, handleMoveChatMessages } from './dragUtils';

const useStyles = makeStyles()(theme => ({
    startDragDiv: {
        cursor: 'grab',
        width: '100%',
        textAlign: 'center',
        zIndex: 10,
        ':after': {
            content: '""',
            display: 'block',
            position: 'relative',
            top: '-27px',
            height: '2px',
            width: '100%',
            backgroundColor: theme.colors.Primary(),
            zIndex: 3,
        },
    },
    startNonDragDiv: {
        width: '100%',
        textAlign: 'center',
        zIndex: 10,
        ':after': {
            content: '""',
            display: 'block',
            position: 'relative',
            top: '-27px',
            height: '2px',
            width: '100%',
            backgroundColor: theme.colors.Primary(),
            zIndex: 3,
        },
    },
    startTextDiv: {
        display: 'flex',
        justifyContent: 'center',
    },
    startText: {
        backgroundColor: 'white',
        width: 'fit-content',
        position: 'relative',
        zIndex: 1000,
    },
}));

type ChatConversationsProps = {
    chatRoom: ChatRoom | null;
    chatRoomId: string;
    currentUserId: string;
};

const ChatConversations = ({ chatRoom, chatRoomId, currentUserId }: ChatConversationsProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const { classes } = useStyles();
    const [draggedConversationId, setDraggedConversationId] = useAtom(draggedConversationIdAtom);
    const setConversationsLoaded = useSetAtom(conversationsLoadedAtom);

    const { data: chatConversationsData, loading: chatConversationsLoading } =
        useChatConversationsV2ForMessageCenterQuery({
            variables: {
                input: {
                    filter: {
                        fields: {
                            chatRoomId,
                        },
                    },
                    orderBy: {
                        field: 'createdAt',
                        order: OrderByDirectionEnum.Asc,
                    },
                },
            },
        });

    useEffect(() => {
        setConversationsLoaded(!chatConversationsLoading);
    }, [chatConversationsLoading, setConversationsLoaded]);

    const [resolveChatConversation] = useResolveChatConversationForMessageCenterMutation({
        onCompleted: () => {
            TriggerGlobalAlert({
                message: 'Conversation resolved',
                severity: AlertSeverity.Success,
            });
        },
        refetchQueries: [{ query: ChatConversationsV2ForMessageCenterDocument }],
        update: (cache, response) => {
            const updatedChatConversations =
                response.data?.resolveChatConversation?.chatConversations;
            const chatConversationsQuery = cache.readQuery<
                ChatConversationsV2ForMessageCenterQuery,
                ChatConversationsV2ForMessageCenterQueryVariables
            >({
                query: ChatConversationsV2ForMessageCenterDocument,
                variables: {
                    input: {
                        filter: {
                            fields: {
                                chatRoomId,
                            },
                        },
                        orderBy: {
                            field: 'createdAt',
                            order: OrderByDirectionEnum.Asc,
                        },
                    },
                },
            });
            if (
                chatConversationsQuery?.chatConversationsV2.results &&
                isChatConversations(updatedChatConversations)
            ) {
                cache.writeQuery<
                    ChatConversationsV2ForMessageCenterQuery,
                    ChatConversationsV2ForMessageCenterQueryVariables
                >({
                    query: ChatConversationsV2ForMessageCenterDocument,
                    variables: {
                        input: {
                            filter: {
                                fields: {
                                    chatRoomId,
                                },
                            },
                            orderBy: {
                                field: 'createdAt',
                                order: OrderByDirectionEnum.Asc,
                            },
                        },
                    },
                    data: {
                        ...chatConversationsQuery,
                        chatConversationsV2: {
                            ...chatConversationsQuery.chatConversationsV2,
                            results: updatedChatConversations,
                        },
                    },
                });
            }
        },
        onError: error => {
            TriggerGlobalAlert({
                message: `Failed to resolve conversation: ${error.message}`,
                severity: AlertSeverity.Error,
            });
        },
    });

    const [moveMessagesInConversations] = useMoveMessagesInChatConversationForMessageCenterMutation(
        {
            onCompleted: () => {
                TriggerGlobalAlert({
                    message: 'Messages moved',
                    severity: AlertSeverity.Success,
                });
            },
            update: (cache, result) => {
                const updatedConversations =
                    result.data?.moveMessagesInChatConversation?.chatConversations;
                const conversationsQuery = cache.readQuery<
                    ChatConversationsV2ForMessageCenterQuery,
                    ChatConversationsV2ForMessageCenterQueryVariables
                >({
                    query: ChatConversationsV2ForMessageCenterDocument,
                    variables: {
                        input: {
                            filter: {
                                fields: {
                                    chatRoomId,
                                },
                            },
                            orderBy: {
                                field: 'createdAt',
                                order: OrderByDirectionEnum.Asc,
                            },
                        },
                    },
                });
                if (
                    conversationsQuery?.chatConversationsV2.results &&
                    isChatConversations(updatedConversations)
                ) {
                    cache.writeQuery<
                        ChatConversationsV2ForMessageCenterQuery,
                        ChatConversationsV2ForMessageCenterQueryVariables
                    >({
                        query: ChatConversationsV2ForMessageCenterDocument,
                        variables: {
                            input: {
                                filter: {
                                    fields: {
                                        chatRoomId,
                                    },
                                },
                                orderBy: {
                                    field: 'createdAt',
                                    order: OrderByDirectionEnum.Asc,
                                },
                            },
                        },
                        data: {
                            ...conversationsQuery,
                            chatConversationsV2: {
                                ...conversationsQuery.chatConversationsV2,
                                results: updatedConversations,
                            },
                        },
                    });
                }
            },
            onError: error => {
                TriggerGlobalAlert({
                    message: `Failed to move messages: ${error.message}`,
                    severity: AlertSeverity.Error,
                });
            },
        },
    );

    const handleResolveConversation = (conversationId: string) => {
        resolveChatConversation({
            variables: {
                input: {
                    id: conversationId,
                    chatRoomId,
                },
            },
        });
    };

    const handleMoveMessages = ({
        startingConversationId,
        endingConversationId,
        messageIds,
    }: {
        startingConversationId: string;
        endingConversationId: string;
        messageIds: string[];
    }) => {
        if (chatConversationsData?.chatConversationsV2.results == null) {
            TriggerGlobalAlert({
                message: 'Failed to move messages: No conversation data found',
                severity: AlertSeverity.Error,
            });
            return;
        }
        const result = handleMoveChatMessages({
            conversations: chatConversationsData?.chatConversationsV2.results,
            startingConversationId,
            endingConversationId,
            messageIds,
        });
        if (!result.success) {
            return;
        }
        moveMessagesInConversations({
            variables: {
                input: {
                    startConversationId: startingConversationId,
                    updatedStartedAt: result.updatedStartedAt,
                    newConversationId: result.endConversationId,
                    messageIds,
                    chatRoomId,
                },
            },
        });
    };

    const handleDragEnd = (event: PointerEvent, info: PanInfo) => {
        if (draggedConversationId == null || chatConversationsData == null) {
            TriggerGlobalAlert({
                message: 'Failed to move conversation: no dragged conversation found',
                severity: AlertSeverity.Error,
            });
            return;
        }

        const result = getDraggedItemHelper(
            event,
            info,
            chatConversationsData.chatConversationsV2.results,
        );
        if (result == null) {
            return;
        }

        const { endConversation, messageIndex } = result;

        if (info.offset.y < 0) {
            // moved up
            let shouldBreak = false;
            const messageIds = endConversation.messages
                ?.filter((_, index) => index >= messageIndex)
                .map(m => {
                    if (
                        new Date(m.createdAt).getTime() <
                        new Date(endConversation.resolvedAt).getTime()
                    ) {
                        TriggerGlobalAlert({
                            message:
                                "Failed to move messages: Can't move a message that would change the resolution time of the previous conversation.",
                            severity: AlertSeverity.Error,
                        });
                        shouldBreak = true;
                        return null;
                    }
                    return m.id;
                });
            if (shouldBreak) {
                return;
            }
            if (messageIds == null) {
                TriggerGlobalAlert({
                    message:
                        'Failed to move messages: Not a valid drop location. Try dropping on the message.',
                    severity: AlertSeverity.Error,
                });
                return;
            }

            handleMoveMessages({
                startingConversationId: draggedConversationId,
                endingConversationId: endConversation.id,
                messageIds: messageIds ?? [],
            });
        } else {
            // moved down
            const messageIds = endConversation.messages
                ?.filter((_, index) => index <= messageIndex)
                .map(m => m.id);
            if (messageIds == null) {
                TriggerGlobalAlert({
                    message: 'Failed to move messages: no message ids found',
                    severity: AlertSeverity.Error,
                });
                return;
            }

            handleMoveMessages({
                startingConversationId: draggedConversationId,
                endingConversationId: endConversation.id,
                messageIds: messageIds ?? [],
            });
        }
        setDraggedConversationId(null);
    };

    if (chatConversationsLoading) {
        return <Loading subtitle="Loading conversation data..." />;
    }

    return (
        <div ref={ref} style={{ overflow: 'auto' }}>
            {chatConversationsData?.chatConversationsV2.results.map((conversation, index) => {
                return (
                    <div
                        key={conversation.id}
                        style={{
                            width: '100%',
                            paddingLeft: '5px',
                            paddingRight: '5px',
                            paddingBottom: '5px',
                            backgroundColor: conversation.resolvedAt ? '' : 'lightcyan',
                        }}
                    >
                        <Grid
                            container
                            direction="column"
                            key={conversation.id}
                            data-conversation-id={conversation.id}
                        >
                            {index ===
                                chatConversationsData?.chatConversationsV2.results.length - 1 ||
                            index === 0 ? (
                                <motion.div
                                    drag="y"
                                    dragConstraints={ref}
                                    dragSnapToOrigin
                                    dragElastic={0}
                                    dragMomentum={false}
                                    className={classes.startDragDiv}
                                    onPanStart={() => setDraggedConversationId(conversation.id)}
                                    onPanEnd={handleDragEnd}
                                >
                                    <div className={classes.startTextDiv}>
                                        <p className={classes.startText}>start of conversation</p>
                                    </div>
                                </motion.div>
                            ) : (
                                <div className={classes.startNonDragDiv}>
                                    <div className={classes.startTextDiv}>
                                        <p className={classes.startText}>start of conversation</p>
                                    </div>
                                </div>
                            )}
                            {conversation.messages?.map(message => {
                                return (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent:
                                                currentUserId === message.senderId
                                                    ? 'flex-end'
                                                    : 'flex-start',
                                        }}
                                        key={message.id}
                                        data-message-id={message.id}
                                    >
                                        <ChatMessage
                                            chatRoom={chatRoom ?? null}
                                            key={message.id}
                                            currentUserId={currentUserId}
                                            chatMessage={message}
                                        />
                                    </div>
                                );
                            })}
                            <div
                                style={{ display: 'flex', flexDirection: 'row' }}
                                data-conversation-id={conversation.id}
                                data-position="bottom"
                            >
                                {conversation.resolvedAt && conversation.resolutionTime ? (
                                    <p>
                                        Resolved at:{' '}
                                        {displayDateWithAbbrTimeZone({
                                            isoDateStr: conversation.resolvedAt,
                                            format: 'MMM DD, h:mm a',
                                            timeZone:
                                                Intl.DateTimeFormat().resolvedOptions().timeZone,
                                        })}
                                        by {conversation.resolvedByName ?? 'Unknown'}
                                        {'. '}
                                        Completed in:{' '}
                                        {Math.round(conversation.resolutionTime / 1000 / 60)}{' '}
                                        minute(s)
                                    </p>
                                ) : (
                                    <Button
                                        startIcon={<FontAwesomeIcon icon={faWrench} />}
                                        color="secondary"
                                        variant="contained"
                                        onClick={() => handleResolveConversation(conversation.id)}
                                    >
                                        Resolve
                                    </Button>
                                )}
                            </div>
                        </Grid>
                    </div>
                );
            })}
        </div>
    );
};

export default ChatConversations;

import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    MenuItem,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CloudUploadOutlined, Delete } from '@mui/icons-material';
import { FileInfo } from '@uploadcare/react-widget';
import _ from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import Loading from '~/components/Loading/Loading';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import useUserPermissions from '~/hooks/useUserPermissions';
import {
    AddressInput,
    AlertSeverity,
    PatientInput,
    PatientInvitation,
    useCreatePatientForPatientsPageMutation,
    useFetchCurrentUserForPatientModalQuery,
    useFetchPatientByIdForPatientModalLazyQuery,
    useUpdatePatientForPatientsPageMutation,
} from '~/schemaTypes';
import { PatientEnum } from '~/selectors';
import { TriggerGlobalAlert, TriggerGlobalImageUploader } from '~/state';
import DateEditor from '~/components/DateEditor/DateEditor';
import { IPatientAttachment } from '../AttachmentsModal/AttachmentsModal';

// eslint-disable-next-line prefer-regex-literals
// const PHONE_REGEX = RegExp(
//     /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
// );

export const PATIENT_VALIDATION_SCHEMA = Yup.object().shape(
    {
        externalId: Yup.string().nullable(),
        practiceId: Yup.string().required('Practice is required'),
        email: Yup.string().email('Invalid Email').required('Email is required'),
        firstName: Yup.string().required('First name required'),
        lastName: Yup.string().required('Last name required'),
        mailingAddress: Yup.object()
            .nullable()
            .shape(
                {
                    street1: Yup.string().when(
                        ['city', 'state', 'code'],
                        ([city, state, code], schema) => {
                            return city || state || code
                                ? schema.required('Complete address required')
                                : schema;
                        },
                    ),
                    street2: Yup.string().nullable(),
                    city: Yup.string().when(
                        ['street1', 'state', 'code'],
                        ([street1, state, code], schema) => {
                            return street1 || state || code
                                ? schema.required('Complete address required')
                                : schema;
                        },
                    ),
                    state: Yup.string().when(
                        ['street1', 'city', 'code'],
                        ([street1, city, code], schema) => {
                            return street1 || city || code
                                ? schema.required('Complete address required')
                                : schema;
                        },
                    ),
                    code: Yup.string().when(
                        ['street1', 'state', 'city'],
                        ([street1, state, city], schema) => {
                            return street1 || state || city
                                ? schema.required('Complete address required')
                                : schema;
                        },
                    ),
                },
                [
                    ['street1', 'city'],
                    ['street1', 'state'],
                    ['street1', 'code'],
                    ['city', 'state'],
                    ['city', 'code'],
                    ['state', 'code'],
                ],
            ),
        phoneNumber: Yup.string()
            .nullable()
            .when('phoneNumber', ([phoneNumber], schema) => {
                return phoneNumber ? schema.required('Phone number is required') : schema;
            }),
        invitationMode: Yup.string().required('Invitation mode is required'),
        riskIndicators: Yup.object()
            .nullable()
            .shape({
                obesity: Yup.object().shape({
                    flag: Yup.bool(),
                    comment: Yup.string(),
                }),
                preterm: Yup.object().shape({
                    flag: Yup.bool(),
                    comment: Yup.string(),
                }),
                chronicHypertension: Yup.object().shape({
                    flag: Yup.bool(),
                    comment: Yup.string(),
                }),
                other: Yup.object().shape({
                    flag: Yup.bool(),
                    comment: Yup.string(),
                }),
            }),
    },
    [['phoneNumber', 'phoneNumber']],
);

const useStyles = makeStyles()({
    root: {},
});

type PatientInvitationType = {
    value: PatientInvitation;
    label: string;
};

const PatientInvitations: PatientInvitationType[] = [
    {
        value: PatientInvitation.None,
        label: 'None',
    },
    {
        value: PatientInvitation.GenerateCode,
        label: 'Generate Code Only',
    },
    {
        value: PatientInvitation.EmailCode,
        label: 'Generate Code and Send Email Invitation',
    },
];
interface PatientFormInput extends Omit<PatientInput, 'mailingAddress' | 'practice' | 'birthDate'> {
    mailingAddress: AddressInput;
    practice: { id: string };
}

type PatientModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    setEditPatientId: Dispatch<SetStateAction<string>>;
    id?: string;
    onCreateCompleted: () => Promise<any>;
};

const PatientModal: React.FC<PatientModalProps> = ({
    setOpen,
    setEditPatientId,
    id,
    onCreateCompleted,
}) => {
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const [isTestData, setIsTestData] = useState<boolean>(false);
    const [attachments, setAttachments] = useState<IPatientAttachment[]>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        control,
        reset,

        formState: { errors },
    } = useForm<PatientFormInput>({
        // @ts-expect-error RHF V7 limitation #7895 will be fixed in V8 of react-hook-form
        resolver: yupResolver(PATIENT_VALIDATION_SCHEMA as any),
    });
    const [dOB, setDOB] = useState('');

    const { data: userData } = useFetchCurrentUserForPatientModalQuery();
    const [getPatient, { data: patientData, loading }] =
        useFetchPatientByIdForPatientModalLazyQuery({
            onCompleted: data => {
                if (data.patient) {
                    const {
                        externalId,
                        practice,
                        email,
                        firstName,
                        lastName,
                        mailingAddress,
                        phoneNumber,
                        invitationMode,
                        riskIndicators,
                        birthDate,
                        attachments,
                        isTestData,
                    } = data.patient;
                    reset({
                        externalId,
                        practiceId: practice.id,
                        email,
                        firstName,
                        lastName,
                        mailingAddress: {
                            street1: mailingAddress?.street1,
                            street2: mailingAddress?.street2,
                            city: mailingAddress?.city,
                            state: mailingAddress?.state,
                            code: mailingAddress?.code,
                        },
                        phoneNumber,
                        invitationMode,
                        riskIndicators,
                    });
                    setDOB(birthDate);
                    setAttachments(
                        attachments?.map(item =>
                            _.omit(item, '__typename'),
                        ) as IPatientAttachment[],
                    );
                    setIsTestData(isTestData ?? false);
                }
            },
            fetchPolicy: 'network-only',
        });

    const [updatePatient, { loading: updatingPatient }] = useUpdatePatientForPatientsPageMutation({
        onCompleted: data => {
            if (data.updatePatient?.success) {
                setOpen(false);
                setEditPatientId('');
            } else if (data.updatePatient?.status === 409) {
                setIsModalOpen(true);
            }
        },
    });

    const [createPatient, { loading: creatingPatient }] = useCreatePatientForPatientsPageMutation({
        onCompleted: data => {
            if (data.createPatient?.success) {
                setOpen(false);
                setEditPatientId('');
                onCreateCompleted();
            }
        },
    });

    const onFileUpload = (file: FileInfo) => {
        const newAttachment: IPatientAttachment | null = file.uuid
            ? {
                  uuid: file.uuid,
                  label: file.name || 'newfile',
              }
            : null;
        if (newAttachment) {
            setAttachments(prevAttachments => [...prevAttachments, newAttachment]);
        }
    };

    const handleDeleteAttachment = (id: string | null) => {
        if (id) {
            const filteredAttachments = attachments?.filter(item => item?.uuid !== id);
            setAttachments(filteredAttachments);
        }
    };

    const onSubmit = (patient: PatientFormInput) => {
        const filteredPatient = pagePermissions?.PatientRiskIndicators.Edit
            ? patient
            : (_.omit(patient, 'riskIndicators') as PatientFormInput);
        if (id) {
            updatePatient({
                variables: {
                    input: {
                        id,
                        data: {
                            ...filteredPatient,
                            attachments,
                            birthDate: dOB,
                            isTestData,
                        },
                    },
                },
            });
        } else if (dOB) {
            createPatient({
                variables: {
                    input: {
                        ...filteredPatient,
                        birthDate: dOB,
                        isTestData,
                    },
                },
            });
        } else {
            TriggerGlobalAlert({
                message: 'Patient DOB is required',
                severity: AlertSeverity.Error,
            });
        }
    };
    const handleCancel = () => {
        setOpen(false);
        setEditPatientId('');
    };
    useEffect(() => {
        if (id) {
            getPatient({
                variables: {
                    input: {
                        id,
                    },
                },
            });
        }
    }, [id, getPatient]);

    if (loading) {
        return <Loading subtitle="Loading patient details..." />;
    }

    if (creatingPatient) {
        return <Loading subtitle="Creating patient..." />;
    }
    if (updatingPatient) {
        return <Loading subtitle="Updating patient..." />;
    }
    return (
        <div>
            <form
                className={classes.root}
                onSubmit={handleSubmit(onSubmit)}
                data-test={PatientEnum.PATIENT_MODAL}
            >
                <DialogTitle id="form-dialog-title">
                    {patientData?.patient?.id === undefined ? 'New Patient' : 'Edit Patient'}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label="Is Test Patient?"
                                labelPlacement="start"
                                control={
                                    <Checkbox
                                        id="isTestData"
                                        {...register('isTestData')}
                                        checked={isTestData}
                                        onChange={e => setIsTestData(e.target.checked)}
                                    />
                                }
                            />
                        </Grid>
                        <DateEditor setSelectedDate={setDOB} initialDate={dOB} title="Birth Date" />
                        <Grid item xs={6}>
                            <ReactHookFormSelect
                                className="selectListItemInput"
                                control={control}
                                disabled={!!id}
                                defaultValue={userData?.currentUser?.currentOrg.id ?? ''}
                                {...register('practiceId')}
                                variant="outlined"
                                label="Practice"
                                fullWidth
                                margin="dense"
                            >
                                {userData?.currentUser?.currentOrganizations.map(
                                    ({ id: orgId, brandingName }) => (
                                        <MenuItem key={orgId} value={orgId}>
                                            {brandingName}
                                        </MenuItem>
                                    ),
                                )}
                            </ReactHookFormSelect>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                error={!!errors.email}
                                label="Email"
                                id="email"
                                type="email"
                                margin="dense"
                                fullWidth
                                helperText={errors.email?.message}
                                data-test={PatientEnum.EMAIL_INPUT}
                                {...register('email')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                error={!!errors.firstName}
                                label="First Name"
                                id="firstName"
                                type="text"
                                margin="dense"
                                fullWidth
                                helperText={errors.firstName?.message}
                                data-test={PatientEnum.FIRST_NAME_INPUT}
                                {...register('firstName')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                error={!!errors.lastName}
                                label="Last Name"
                                id="lastName"
                                type="text"
                                margin="dense"
                                fullWidth
                                helperText={errors.lastName?.message}
                                data-test={PatientEnum.LAST_NAME_INPUT}
                                {...register('lastName')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ReactHookFormSelect
                                className="selectListItemInput"
                                control={control}
                                disabled={!!id}
                                defaultValue={PatientInvitation.EmailCode}
                                {...register('invitationMode')}
                                variant="outlined"
                                label="Invitation Mode"
                                fullWidth
                                margin="dense"
                            >
                                {PatientInvitations.map(({ value, label }) => (
                                    <MenuItem key={value} value={value}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                label="Medical Record Id"
                                id="externalId"
                                type="text"
                                margin="dense"
                                fullWidth
                                data-test={PatientEnum.MEDICAL_RECORD_ID_INPUT}
                                {...register('externalId')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                error={!!errors.phoneNumber}
                                label="Phone Number"
                                id="phone"
                                type="text"
                                margin="dense"
                                fullWidth
                                helperText={errors.phoneNumber?.message}
                                data-test={PatientEnum.PHONE_NUMBER_INPUT}
                                {...register('phoneNumber')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                error={!!errors.mailingAddress?.street1}
                                label="Street Address 1"
                                id="street1"
                                type="text"
                                margin="dense"
                                fullWidth
                                {...register('mailingAddress.street1')}
                                helperText={errors.mailingAddress?.street1?.message}
                                data-test={PatientEnum.STREET_ADDRESS_1_INPUT}
                                {...register('mailingAddress.street1')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                label="Street Address 2"
                                id="street2"
                                type="text"
                                margin="dense"
                                fullWidth
                                data-test={PatientEnum.STREET_ADDRESS_2_INPUT}
                                {...register('mailingAddress.street2')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                variant="outlined"
                                error={!!errors.mailingAddress?.city}
                                label="City"
                                id="city"
                                type="text"
                                margin="dense"
                                fullWidth
                                helperText={errors.mailingAddress?.city?.message}
                                data-test={PatientEnum.CITY_INPUT}
                                {...register('mailingAddress.city')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                variant="outlined"
                                error={!!errors.mailingAddress?.state}
                                label="State"
                                id="state"
                                type="text"
                                margin="dense"
                                fullWidth
                                helperText={errors.mailingAddress?.state?.message}
                                data-test={PatientEnum.STATE_INPUT}
                                {...register('mailingAddress.state')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                variant="outlined"
                                error={!!errors.mailingAddress?.code}
                                label="Zip Code"
                                id="code"
                                type="text"
                                margin="dense"
                                fullWidth
                                helperText={errors.mailingAddress?.code?.message}
                                data-test={PatientEnum.ZIP_CODE_INPUT}
                                {...register('mailingAddress.code')}
                            />
                        </Grid>
                        {pagePermissions?.PatientRiskIndicators.Edit && (
                            <>
                                <Grid item xs={12}>
                                    <Typography paragraph variant="h6">
                                        Risk Indicators
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{ marginLeft: 10 }}>
                                    <Typography variant="body1">High Risk Obesity:</Typography>
                                </Grid>
                                <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={6}>
                                    <FormControlLabel
                                        style={{ marginBottom: 0, marginLeft: 5 }}
                                        control={
                                            <Switch
                                                {...register('riskIndicators.obesity.flag')}
                                                defaultChecked={
                                                    patientData?.patient?.riskIndicators?.obesity
                                                        ?.flag ?? false
                                                }
                                            />
                                        }
                                        label="Risk Flag"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        label="Obesity Flag Comments"
                                        id="patientObesityFlag"
                                        type="text"
                                        margin="dense"
                                        fullWidth
                                        {...register('riskIndicators.obesity.comment')}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ marginLeft: 10 }}>
                                    <Typography variant="body1">
                                        High Risk Previous Preterm:
                                    </Typography>
                                </Grid>
                                <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={6}>
                                    <FormControlLabel
                                        style={{ marginBottom: 0, marginLeft: 5 }}
                                        control={
                                            <Switch
                                                {...register('riskIndicators.preterm.flag')}
                                                defaultChecked={
                                                    patientData?.patient?.riskIndicators?.preterm
                                                        ?.flag ?? false
                                                }
                                            />
                                        }
                                        label="Risk Flag"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        label="Preterm Flag Comments"
                                        id="patientPretermFlag"
                                        type="text"
                                        margin="dense"
                                        fullWidth
                                        {...register('riskIndicators.preterm.comment')}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ marginLeft: 10 }}>
                                    <Typography variant="body1">
                                        High Risk Chronic Hypertension:
                                    </Typography>
                                </Grid>
                                <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={6}>
                                    <FormControlLabel
                                        style={{ marginBottom: 0, marginLeft: 5 }}
                                        control={
                                            <Switch
                                                {...register(
                                                    'riskIndicators.chronicHypertension.flag',
                                                )}
                                                defaultChecked={
                                                    patientData?.patient?.riskIndicators
                                                        ?.chronicHypertension?.flag ?? false
                                                }
                                            />
                                        }
                                        label="Risk Flag"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        label="Chronic Hypertension Flag Comments"
                                        id="patientChronicHypertensionFlag"
                                        type="text"
                                        margin="dense"
                                        fullWidth
                                        {...register('riskIndicators.chronicHypertension.comment')}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ marginLeft: 10 }}>
                                    <Typography variant="body1">
                                        Other Risk (specified in comments):
                                    </Typography>
                                </Grid>
                                <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={6}>
                                    <FormControlLabel
                                        style={{ marginBottom: 0, marginLeft: 5 }}
                                        control={
                                            <Switch
                                                {...register('riskIndicators.other.flag')}
                                                defaultChecked={
                                                    patientData?.patient?.riskIndicators?.other
                                                        ?.flag ?? false
                                                }
                                            />
                                        }
                                        label="Risk Flag"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        label="Other Risk Comments"
                                        id="patientOtherFlag"
                                        type="text"
                                        margin="dense"
                                        fullWidth
                                        {...register('riskIndicators.other.comment')}
                                    />
                                </Grid>
                                <Grid item xs={12} spacing={1}>
                                    <Grid item>
                                        <Typography variant="h6">Attachments</Typography>
                                    </Grid>
                                    <Grid item>
                                        <List>
                                            {attachments?.map(item => (
                                                <li key={item.uuid}>
                                                    <span>{item?.label}</span>
                                                    <IconButton
                                                        onClick={() =>
                                                            handleDeleteAttachment(item.uuid)
                                                        }
                                                        size="large"
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </li>
                                            ))}
                                        </List>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<CloudUploadOutlined />}
                                            onClick={() =>
                                                TriggerGlobalImageUploader({
                                                    callback: onFileUpload,
                                                })
                                            }
                                        >
                                            Upload
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions
                    style={{
                        position: 'sticky',
                        bottom: 0,
                        backgroundColor: 'white',
                        zIndex: 1000,
                    }}
                >
                    <Button onClick={handleCancel} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        data-test={PatientEnum.SUBMIT_PATIENT}
                    >
                        {id ? 'Update' : 'Create Patient'}
                    </Button>
                </DialogActions>
            </form>
            <Dialog scroll="paper" open={isModalOpen} fullWidth>
                <DialogTitle>This email could not be saved</DialogTitle>
                <DialogContent>
                    This email is already being used by a patient in this org.
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setIsModalOpen(false)}
                        type="submit"
                        color="primary"
                        variant="contained"
                    >
                        OK
                    </Button>
                    <Button
                        onClick={() => setIsModalOpen(false)}
                        color="secondary"
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PatientModal;

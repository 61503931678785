import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormHelperText,
    Grid,
    Input,
    TextField,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import Loading from '~/components/Loading/Loading';
import {
    AlertSeverity,
    FetchUserProfileDefinitionsForVbcPatientModalQuery,
    useCreateAppUserForPatientMutation,
    useCreateOrUpdateManyUserProfileVariablesMutation,
    useFetchConfigValueOrDefaultForVbcPatientModalLazyQuery,
    useFetchPatientByIdForVbcPatientModalLazyQuery,
    useFetchUserProfileDefinitionsForVbcPatientModalQuery,
    useUpdatePatientOnPatientsPageMutation,
} from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import ProfileVariableInputs from '~/views/Dashboard/Patients/VBCPatientModal/ProfileVariableInputs';
import DateEditor from '~/components/DateEditor/DateEditor';
import { isMobilePhone, isEmail } from 'class-validator';
import { useProfileVariableInputs } from './useProfileVariableInputs';

import { useStyles } from './styles';

interface CreateVBCAppUserForPatientInput {
    [key: string]: any;
}

type PatientModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    setEditPatientId: Dispatch<SetStateAction<string>>;
    id?: string;
    bundleId: string | null;
    onCreateCompleted: () => Promise<any>;
};

type ProfileDefinitionsData =
    FetchUserProfileDefinitionsForVbcPatientModalQuery['userProfileDefsV2']['results'];
type ProfileDefinitionsDataItem = ProfileDefinitionsData[0];

const INVITATION_MODE_CONFIG_ID = '6359b90ff5d408671fb295a1';

const canLoginByPhoneNumber = (invitationModeConfig?: string | null) => {
    return invitationModeConfig === 'EMAIL_OR_SMS_CODE';
};

const ERROR_MESSAGES: Record<'EMAIL_IN_USE' | 'PHONE_IN_USE', { title: string; message: string }> =
    {
        EMAIL_IN_USE: {
            title: 'This email could not be saved',
            message: 'This email is already being used by a patient in this org.',
        },
        PHONE_IN_USE: {
            title: 'This phone number could not be saved',
            message: 'This phone number is already being used by a patient in this org.',
        },
    };

const VBCPatientModal: React.FC<PatientModalProps> = ({
    setOpen,
    onCreateCompleted,
    bundleId,
    setEditPatientId,
    id,
}) => {
    const { classes } = useStyles();
    const [modalData, setModalData] = useState<{ title: string; message: string } | null>(null);

    const [fetchInviteCodeConfigValue, { data: inviteCodeConfigValueData }] =
        useFetchConfigValueOrDefaultForVbcPatientModalLazyQuery({
            variables: {
                input: { configValueId: INVITATION_MODE_CONFIG_ID, bundleId: bundleId as string },
            },
        });

    const [getPatientForValidation] = useFetchPatientByIdForVbcPatientModalLazyQuery();
    const [dOB, setDOB] = useState('');

    const getPatientValidationSchema = useCallback(() => {
        return Yup.object().shape({
            appBundleId: Yup.string().required('App is required'),
            first_name: Yup.string().required('First Name is required'),
            last_name: Yup.string().required('Last Name is required'),
            email: Yup.string()
                .test('email', 'Email is required', async value => {
                    if (value) {
                        return true;
                    }
                    const { data } = await fetchInviteCodeConfigValue();

                    const invitationCodeConfigValue = data?.ConfigValueOrDefault?.str;

                    return canLoginByPhoneNumber(invitationCodeConfigValue);
                })
                .test('email', 'Email is not valid', value => {
                    if (!value) {
                        return true;
                    }
                    return isEmail(value);
                }),
            phone_number: Yup.string().test('phone_number', 'Phone number is not valid', value => {
                if (!value) {
                    return true;
                }

                return isMobilePhone(value);
            }),
            isTestData: Yup.boolean(),
        });
    }, [fetchInviteCodeConfigValue]);

    const useFormObject = useForm<CreateVBCAppUserForPatientInput>({
        resolver: yupResolver(getPatientValidationSchema() as any),
    });

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
        setError,
    } = useFormObject;
    const { profileDefinitionsData, profileVariableInputs } = useProfileVariableInputs({
        useFormObject,
        patientId: id,
        bundleId,
        VBCPatientModalInputs: ProfileVariableInputs,
    });

    const [getPatient, { data: patientData, loading: patientLoading }] =
        useFetchPatientByIdForVbcPatientModalLazyQuery({
            onCompleted: data => {
                if (data.patient) {
                    const { firstName, lastName, email, phoneNumber, birthDate, isTestData } =
                        data.patient;

                    reset({
                        first_name: firstName ?? '',
                        last_name: lastName ?? '',
                        phone_number: phoneNumber ?? '',
                        email: email ?? '',
                        isTestData,
                    });
                    setDOB(birthDate);
                }
            },
        });

    const [createOrUpdateManyUserProfileVariables] =
        useCreateOrUpdateManyUserProfileVariablesMutation({
            onError: error => {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: `${error}`,
                });
            },
            onCompleted: data => {
                if (!data.createOrUpdateManyUserProfileVariables) {
                    TriggerGlobalAlert({
                        severity: AlertSeverity.Warning,
                        message: `Some Profile Variables could not be saved.`,
                    });
                }
            },
        });

    const [updatePatient, { loading: updatePatientLoading }] =
        useUpdatePatientOnPatientsPageMutation({
            onCompleted: data => {
                if (data.updatePatient?.resourceUpdated?.id) {
                    setOpen(false);
                    setEditPatientId('');
                    onCreateCompleted();
                }
            },
            onError: error => {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: `${error}`,
                });
            },
        });

    const [createPatient, { loading: createPatientLoading }] = useCreateAppUserForPatientMutation({
        onCompleted: data => {
            if (
                data.createAppUserForPatient?.appUserId &&
                data.createAppUserForPatient?.patientId
            ) {
                setOpen(false);
                setEditPatientId('');
                onCreateCompleted();
            }
        },
        onError: error => {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: `${error}`,
            });
        },
    });

    const { data: patientProfileDefinitionsData, loading: patientProfileDefinitionsLoading } =
        useFetchUserProfileDefinitionsForVbcPatientModalQuery({
            variables: {
                input: {
                    filter: {
                        fieldsInList: {
                            name: [
                                'email',
                                'first_name',
                                'last_name',
                                'phone_number',
                                'birth_date',
                            ],
                        },
                    },
                },
            },
        });

    useEffect(() => {
        fetchInviteCodeConfigValue();
    }, [fetchInviteCodeConfigValue]);

    const getCreateOrUpdateProfileVariablesInput = (patientId: string, additionalData: any) => {
        return [
            ...profileDefinitionsData,
            ...(patientProfileDefinitionsData?.userProfileDefsV2.results ?? []),
        ].map((item: ProfileDefinitionsDataItem) => ({
            patientId,
            profileVariableDefId: item.id,
            value: {
                [item.valueType]: additionalData[item.name],
            },
        }));
    };

    const onSubmit = async (state: CreateVBCAppUserForPatientInput) => {
        const mappedState: CreateVBCAppUserForPatientInput = {
            ...state,
            phone_number: state.phone_number || undefined,
            email: state.email || undefined,
        };
        const { appBundleId, isTestData, ...additionalData } = mappedState;

        if (!mappedState.email && !mappedState.phone_number) {
            setError('emailOrPhoneNumberRequired', {
                type: 'manual',
                message: 'email or phone number required',
            });
            return;
        }

        if (mappedState.phone_number) {
            const { data } = await getPatientForValidation({
                variables: {
                    input: {
                        fields: {
                            phoneNumber: mappedState.phone_number,
                            appBundleId: bundleId,
                        },
                    },
                },
            });

            if (
                data?.patient?.id &&
                patientData?.patient?.phoneNumber !== mappedState.phone_number
            ) {
                setModalData(ERROR_MESSAGES.PHONE_IN_USE);
                return;
            }
        }

        if (mappedState.email) {
            const { data } = await getPatientForValidation({
                variables: {
                    input: {
                        fields: {
                            email: mappedState.email,
                            appBundleId: bundleId,
                        },
                    },
                },
            });

            if (data?.patient?.id && patientData?.patient?.email !== mappedState.email) {
                setModalData(ERROR_MESSAGES.EMAIL_IN_USE);
                return;
            }
        }

        if (id) {
            await updatePatient({
                variables: {
                    input: {
                        id,
                        data: {
                            firstName: mappedState.first_name,
                            lastName: mappedState.last_name,
                            email: mappedState.email,
                            phoneNumber: mappedState.phone_number,
                            isTestData,
                            birthDate: dOB,
                        },
                    },
                },
            });

            createOrUpdateManyUserProfileVariables({
                variables: {
                    input: getCreateOrUpdateProfileVariablesInput(id, additionalData),
                },
            });
        } else {
            const createPatientResponse = await createPatient({
                variables: {
                    input: {
                        firstName: mappedState.first_name,
                        lastName: mappedState.last_name,
                        email: mappedState.email,
                        phoneNumber: mappedState.phone_number,
                        appBundleId,
                        isTestData,
                        birthDate: dOB,
                        isCreatedByPortal: true,
                    },
                },
            });
            const patientId = createPatientResponse?.data?.createAppUserForPatient?.patientId;
            if (!patientId) return;
            createOrUpdateManyUserProfileVariables({
                variables: {
                    input: getCreateOrUpdateProfileVariablesInput(patientId, additionalData),
                },
            });
        }
    };

    useEffect(() => {
        if (id) {
            getPatient({
                variables: {
                    input: {
                        id,
                    },
                },
            });
        }
    }, [id, getPatient]);

    if (patientLoading || patientProfileDefinitionsLoading) {
        return <Loading subtitle="Loading patient..." />;
    }
    if (createPatientLoading) {
        return <Loading subtitle="Creating patient..." />;
    }
    if (updatePatientLoading) {
        return <Loading subtitle="Updating patient..." />;
    }
    const handleCancel = () => {
        setOpen(false);
    };

    const emailOrPhoneNumberRequiredError = errors.emailOrPhoneNumberRequired?.message as string;
    const firstNameError = errors?.first_name?.message as string;
    const lastNameError = errors?.last_name?.message as string;
    const emailError = errors?.email?.message as string;
    const phoneNumberError = errors?.phone_number?.message as string;
    const birthDateError = errors?.birth_date?.message as string;
    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">
                    {patientData?.patient?.id === undefined ? 'Create Patient' : 'Edit Patient'}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container>
                        <Grid item xs={12}>
                            <Controller
                                name="isTestData"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        label="Is Test Patient?"
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                {...field}
                                                id="isTestData"
                                                checked={!!field.value}
                                            />
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                value={bundleId}
                                error={!!errors.appBundleId}
                                type="hidden"
                                {...register('appBundleId')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="First Name"
                                type="text"
                                margin="dense"
                                fullWidth
                                error={!!errors.first_name}
                                {...register('first_name')}
                            />
                            {firstNameError && (
                                <FormHelperText error className={classes.inputError}>
                                    {firstNameError}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Last Name"
                                type="text"
                                margin="dense"
                                fullWidth
                                error={!!errors.last_name}
                                {...register('last_name')}
                            />
                            {lastNameError && (
                                <FormHelperText error className={classes.inputError}>
                                    {lastNameError}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Email"
                                type="text"
                                margin="dense"
                                fullWidth
                                error={!!errors.email}
                                {...register('email')}
                            />
                            {emailError && (
                                <FormHelperText error className={classes.inputError}>
                                    {emailError}
                                </FormHelperText>
                            )}
                        </Grid>
                        {canLoginByPhoneNumber(
                            inviteCodeConfigValueData?.ConfigValueOrDefault?.str,
                        ) && (
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    label="Phone number"
                                    type="number"
                                    margin="dense"
                                    fullWidth
                                    error={!!errors.phone_number}
                                    {...register('phone_number')}
                                />
                                {phoneNumberError && (
                                    <FormHelperText error className={classes.inputError}>
                                        {phoneNumberError}
                                    </FormHelperText>
                                )}
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <DateEditor
                                setSelectedDate={setDOB}
                                initialDate={dOB}
                                title="Birth Date"
                            />
                            {birthDateError && (
                                <FormHelperText error className={classes.inputError}>
                                    {birthDateError}
                                </FormHelperText>
                            )}
                        </Grid>
                        {profileVariableInputs}
                    </Grid>
                    {emailOrPhoneNumberRequiredError && (
                        <FormHelperText error>{emailOrPhoneNumberRequiredError}</FormHelperText>
                    )}
                </DialogContent>
                <DialogActions
                    style={{
                        position: 'sticky',
                        bottom: 0,
                        backgroundColor: 'white',
                        zIndex: 1000,
                    }}
                >
                    <Button onClick={handleCancel} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button color="primary" variant="contained" type="submit">
                        {id ? 'Update' : 'Create Patient'}
                    </Button>
                </DialogActions>
            </form>
            <Dialog scroll="paper" open={Boolean(modalData)} fullWidth>
                <DialogTitle>{modalData?.title}</DialogTitle>
                <DialogContent>{modalData?.message}</DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setModalData(null)}
                        type="submit"
                        color="primary"
                        variant="contained"
                    >
                        OK
                    </Button>
                    <Button onClick={() => setModalData(null)} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default VBCPatientModal;
